import React, { useContext } from 'react';
import { HeaderContext } from './HeaderContext';

function Header() {

    const { headerTitle } = useContext(HeaderContext);

    return (
      <div className="header-area">
        <div className="header">
          <h1 className="head-title">{headerTitle}</h1>
            <h2 className="head-title">Paval Sebastian</h2>
            <div className="nav">
                <a href="/courses">Cursuri</a>
                <a href="/#projects">Proiecte</a>
            </div>
        </div>
      </div>
    );
  }
  export default Header;