import React from 'react';

function Course({ name, description, technologies }) {
  return (
    <div className="course">
      <h2 className="course-name">{name}</h2>
      <p className="course-description">{description}</p>
      <ul className="course-technologies">
        {technologies.map((tech, index) => (
          <li key={index} className="technology-item">{tech}</li>
        ))}
      </ul>
    </div>
  );
}

export default Course;
