import React, { createContext, useState } from 'react';


export const HeaderContext = createContext();


export const HeaderProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState("Proiecte personale");

  return (
    <HeaderContext.Provider value={{ headerTitle, setHeaderTitle }}>
      {children}
    </HeaderContext.Provider>
  );
};
