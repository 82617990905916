import './App.css';
import Navbar from './NavBar';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import CoursesPage from './CoursesPage';
import Footer from './Footer';
import { HeaderProvider } from './HeaderContext';

function App() {
  return (
    <>
    <HeaderProvider>
      <Router>
        <Navbar />
        <Header/>
        <Routes>
          <Route path='*' element={<HomePage />} />
          <Route path='/courses' element={<CoursesPage/>}/>
          <Route path='/' element={<HomePage />} />
        </Routes>
        <Footer/>
      </Router>
      </HeaderProvider>
    </>
  );
}

export default App;
