import Course from './Course';
import React, { useContext, useEffect } from 'react';
import { HeaderContext } from './HeaderContext';

function CoursesPage() {

    const { setHeaderTitle } = useContext(HeaderContext);

    useEffect(() => {
      setHeaderTitle("Cursuri");
    }, [setHeaderTitle]);

    const courses = [
        {
            name: "Cegeka Academy 2023 .NET Stream",
            description: " After being accepted into the course, we started learning C# along with .NET (ASP.NET), followed by HTML, CSS, and JavaScript (React). The database used was a relational SQL Server, with Entity Framework simplifying data manipulation. The second part of the course involved a team project following the Agile methodology, with one-week sprints. During this phase, we, the students, were responsible for both submitting pull requests and performing code reviews.",
            technologies: ["ASP.NET", "SQL Server", "Azure DevOps", "EntityFramework"]
        },
        {
            name:"Veoneer Unic 2023 C#",
            description:" In this course, we started with learning the C# language, including data types and variables, OOP, tasks and threads, and many other topics. We then moved on to learning the WPF framework and how to structure a project in layers, separating the backend from the frontend to allow simultaneous work on both while minimizing conflicts.",
            technologies: ["C#", "WPF", "XAML"]
        }
    ];

    return (
        <>
            <div className="courses-list">
                {courses.map((course, index) => (
                    <Course
                        key={index}
                        name={course.name}
                        description={course.description}
                        technologies={course.technologies}
                    />
                ))}
            </div>
        </>
    );
}
export default CoursesPage;