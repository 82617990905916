import React from 'react';


function Project({ name, description, technologies, githubLink, projectLink=null }) {
  return (
    <div className="project">
      <h2 className="project-name">{name}</h2>
      <p className="project-description">{description}</p>
      <ul className="project-technologies">
        {technologies.map((tech, index) => (
          <li key={index} className="technology-item">{tech}</li>
        ))}
      </ul>
      <a href={githubLink} target="_blank" rel="noopener noreferrer" className="button-github">
        View on GitHub
      </a>
      {projectLink && (
        <a href={projectLink} target="_blank" rel="noopener noreferrer" className="button-project">
          View Project
        </a>
      )}
    </div>
  );
}

export default Project;
