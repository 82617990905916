import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a href="https://www.linkedin.com/in/pav%C4%83l-sebastian-38a76b161/" target="_blank" rel="noopener noreferrer" className="footer-link">
          <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
        </a>
        <a href="https://github.com/seby1621199/" target="_blank" rel="noopener noreferrer" className="footer-link">
          <FontAwesomeIcon icon={faGithub} /> GitHub
        </a>
        <a href="mailto:pavalsebastiannn16@gmail.com" className="footer-link">
          <FontAwesomeIcon icon={faEnvelope} /> Email
        </a>
      </div>
    </footer>
  );
}

export default Footer;
